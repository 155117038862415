import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { useRandom } from '@oberoninternal/travelbase-ds/hooks/useRandom';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box } from '@rebass/grid';
import omit from 'lodash.omit';
import React, { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { FilterCategory } from '../entities/Filter';
import FilterListCategory from './FilterListCategory';
import Stack, { StackProps } from './Stack';
import FilterSVG from './svg/Filter.svg';

interface FilterListProps extends StackProps {
    categories: FilterCategory[];
    categoryComponents?: Record<string, ComponentType<React.PropsWithChildren<{ category: FilterCategory }>>>;
    loading?: never;
}

interface LoadingProps extends StackProps {
    loading: true;
}

const categorySize = 4;

/**
 * Renders a list from the given `categories` and can optionally render a different `CategoryComponent` by using the `categoryComponents` prop. It also contains a spinner out of the box, which you can use by using the `loading` prop.
 */
const FilterList = (props: FilterListProps | LoadingProps) => {
    if (props.loading) {
        return (
            <Stack spacing={5} {...omit(props, 'loading')}>
                {new Array(3).fill(null).map((_, i) => (
                    <Loader key={i} />
                ))}
            </Stack>
        );
    }

    return <FilterListContent {...props} />;
};

export default FilterList;

const FilterListContent = (props: FilterListProps) => {
    const { categoryComponents } = props;
    const { open, onOpen } = useSesame();
    const categories = props.categories.filter(category => !!category.filters?.length);
    const filtersAmount = categories.flatMap(category => category.filters).length;

    if (!filtersAmount) {
        return null;
    }

    return (
        <Stack spacing={4} {...props} mt={4} pt={4}>
            {categories.slice(0, open ? undefined : categorySize).map(category => {
                const CategoryComponent = categoryComponents?.[category.handle] ?? FilterListCategory;
                return <CategoryComponent key={category.handle} category={category} />;
            })}
            {!open && categories.length > categorySize && (
                <StyledTextButton style={{ display: 'inline-flex' }} onClick={onOpen} size="tiny">
                    <FilterIcon />
                    <FormattedMessage defaultMessage="Meer filters tonen" />
                </StyledTextButton>
            )}
        </Stack>
    );
};

const Loader = () => {
    const random = useRandom(130, 200);
    return (
        <Box>
            <Skeleton width={random} height="1.5rem" />
            <Stack mt={3} spacing={3}>
                {new Array(4).fill(null).map((_, i) => (
                    <Stack alignItems="center" variant="inline" spacing={4} key={i}>
                        <Skeleton width="2.5rem" height="2.5rem" />
                        <Skeleton width="10rem" height="1.5rem" />
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
};

const FilterIcon = styled(FilterSVG)`
    color: inherit;
`;

const StyledTextButton = styled(TextButton)`
    color: ${({ theme }) => theme.colors.neutral['100']};
    span {
        font-weight: 600;
    }

    &:hover span {
        border: none;
    }

    span {
        font-weight: 600;

        svg {
            margin-right: 1.2rem;
        }
    }
    transition: color 0.15s ease-in-out;
`;
